<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.gestion')} / ${$t('menu.finanzas')} / ${$t('lbl.balance')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'finanzas-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.name_comercial"
                      :label="$t('lbl.name')"
                      outlined
                      dense
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.payMethod') }}</strong>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-radio-group
                          v-model="model.method_pay_id"
                          row
                        >
                          <v-radio
                            v-for="(method, index) in methodPays"
                            :key="index"
                            :label="method.name"
                            :value="method.id"
                            @click="changeMethodPay(method)"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="typesMethodPays.length > 0"
                    cols="12"
                    md="6"
                  >
                    <strong>{{ $t('lbl.wayMethod') }}</strong>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-radio-group
                          v-model="model.type_method_pay_id"
                          row
                        >
                          <v-radio
                            v-for="(type, index) in typesMethodPays"
                            :key="index"
                            :label="type.name"
                            :value="type.id"
                            @click="changeTypeMethod(type)"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        v-if="showMonto"
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="monto"
                          prefix="$"
                          :label="$t('lbl.monto')"
                          outlined
                          dense
                          hide-details
                          type="number"
                        ></v-text-field><br />
                      </v-col>
                      <v-col
                        v-if="showTime"
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="time"
                          :items="times"
                          item-text="name"
                          item-value="id"
                          :label="$t('lbl.time')"
                          outlined
                          dense
                          hide-details
                          :suffix="$t('lbl.day')"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    v-if="typesMethodPays.length > 0"
                    cols="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <b>{{ $t('lbl.limitCredit') }}: </b>
                        <span v-if="!model.infiniteBalance">{{ model.limitCredit | currency }}</span>
                        <span v-else>~</span>
                        <br />

                        <b>{{ $t('lbl.creditRest') }}: </b>
                        <span v-if="!model.infiniteBalance">{{ model.creditRestante | currency }}</span>
                        <span v-else>~</span>
                        <br />

                        <fragment v-if="model.time > 0">
                          <b>{{ $t('lbl.limitTime') }}: </b>
                          {{ `${model.time} ${$t('lbl.day')}` }}
                          <br />
                          <b>{{ $t('lbl.timeAvailable') }}: </b>
                          {{ `${model.timeDisponible} ${$t('lbl.day')}` }}
                          <br />
                        </fragment>

                        <b>{{ $t('lbl.deuda') }}: </b>{{ model.deuda | currency }}
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        class="text-right"
                      >
                        <b>{{ $t('lbl.fondoRest') }}: </b>{{ model.fondoRestante | currency }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!--DEPOSITOS-->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <b>{{ $t('lbl.deposit') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  icon
                  @click="showDeposit = !showDeposit"
                >
                  <v-icon>
                    {{ showDeposit ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <Depositos
              v-if="showDeposit"
              :user="user"
              :items="[]"
            />
            <v-divider class="mb-2 mt-2"></v-divider>

            <!--CREDITOS-->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <b>{{ $t('lbl.credits') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  icon
                  @click="showCredit = !showCredit"
                >
                  <v-icon>
                    {{ showCredit ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <Creditos
              v-if="showCredit"
              :user="user"
              :items="[]"
            />
            <v-divider class="mb-2 mt-2"></v-divider>

            <!--OPERACIONES CONTABLES-->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <b>{{ $t('lbl.operaCont') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  icon
                  @click="showOperaCont = !showOperaCont"
                >
                  <v-icon>
                    {{ showOperaCont ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <OperationsContable
              v-if="showOperaCont"
              :user="user"
              :items="[]"
            />
            <v-divider class="mb-2 mt-2"></v-divider>

            <!--COMPROBANTES DE PAGOS-->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <b>{{ $t('lbl.compPay') }}</b>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-btn
                  icon
                  @click="showCompPay = !showCompPay"
                >
                  <v-icon>
                    {{ showCompPay ? icons.mdiMenuDown : icons.mdiMenuRight }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <ComprobantPay
              v-if="showCompPay"
              :user="user"
              :items="[]"
            />
            <v-divider class="mb-2 mt-2"></v-divider>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

import Depositos from './Depositos.vue'
import Creditos from './Creditos.vue'
import OperationsContable from './OperationsContable.vue'
import ComprobantPay from './ComprobantPay.vue'

export default {
  components: {
    AppCardCode,
    Depositos,
    Creditos,
    OperationsContable,
    ComprobantPay,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiMenuDown,
        mdiMenuRight,
      },
      model: {},
      loading: false,
      products: [],
      contacts: [],
      typeAfiliados: [],
      contactNew: {
        id: null,
        name: null,
        email: null,
        role_id: null,
        telefonos: [],
        products: [],
      },
      nom_tipo_products_contact: [],
      telefonos: [],
      telefono: {
        telefono: null,
        ext: null,
      },
      posContact: -1,
      nom_tipo_products_id: [],
      rols: [],
      is_proveedor: false,
      isModalOffline: false,
      idAfiliado: 0,
      user: {},
      methodPays: [],
      typesMethodPays: [],
      showMonto: false,
      showTime: false,
      times: [],
      monto: null,
      time: null,
      showDeposit: false,
      showCredit: false,
      showOperaCont: false,
      showCompPay: false,
    }
  },
  computed: {
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
    }),
  },
  created() {
    if (sessionStorage.getItem('user-register-id') !== null) {
      this.idAfiliado = sessionStorage.getItem('user-register-id')
    }

    this.times.push({
      id: 7,
      name: 7,
    })
    this.times.push({
      id: 15,
      name: 15,
    })
    this.times.push({
      id: 30,
      name: 30,
    })

    // this.getMethodPay()
    this.getItem()
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'addContactsAfiliados', 'deleteContactsAfiliados', 'updateContactsAfiliados']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
      // eslint-disable-next-line no-return-assign
    },
    getMethodPay() {
      this.axios
        .get('nom_methods_pay?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.methodPays = res.data.data
          if (this.model.method_pay_id) {
            if (this.methodPays.filter(e => e.id === this.model.method_pay_id).length > 0) {
              if (this.methodPays.filter(e => e.id === this.model.method_pay_id)[0].types.length > 0) {
                this.typesMethodPays = this.methodPays.filter(e => e.id === this.model.method_pay_id)[0].types
              }
            }
          } else if (this.methodPays.filter(e => e.slug === 'prepago').length > 0) {
            this.model.method_pay_id = this.methodPays.filter(e => e.slug === 'prepago')[0].id
          }
        })
    },
    getItem() {
      if (sessionStorage.getItem('user-register-id') !== null) {
        const id = sessionStorage.getItem('user-register-id')
        this.axios
          .get(`afiliados/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.data_method_pay) {
              if (this.model.data_method_pay.monto) {
                this.monto = this.model.data_method_pay.monto
                this.showMonto = true
              }
              if (this.model.data_method_pay.time) {
                this.time = this.model.data_method_pay.time
                this.showTime = true
              }
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.getMethodPay()
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },

    changeMethodPay(method) {
      if (method.types) {
        this.typesMethodPays = method.types
      }
      this.model.type_method_pay_id = null
    },
    changeTypeMethod(type) {
      if (type.slug === 'monto-de-dinero') {
        this.showMonto = true
        this.showTime = false
        this.time = null
      } else if (type.slug === 'monto-y-tiempo') {
        this.showMonto = true
        this.showTime = true
      } else if (type.slug === 'tiempo') {
        this.showTime = true
        this.showMonto = false
        this.monto = null
      }
    },

    save() {
      const json = {
        method_pay_id: this.model.method_pay_id,
        type_method_pay_id: this.model.type_method_pay_id,
        monto: this.monto,
        time: this.time,
      }

      const procesar = true
      if (procesar) {
        this.loading = true
        if (sessionStorage.getItem('user-register-id') !== null) {
          this.axios
            .post(`afiliados/update-payments/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                }
              } else {
                sessionStorage.removeItem('user-register-id')
                this.$router.push({ name: 'finanzas-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    reset() {
      this.posContact = -1
      this.contactNew = {
        id: null,
        name: null,
        email: null,
        role_id: null,
        telefonos: [],
        products: [],
      }
      this.nom_tipo_products_contact = []
      this.telefonos = []
    },
  },
}
</script>
